<template>
    <div>
        <div v-for="item in stepContentList" :key="item.text">
            <p class="my-4" v-html="$t(`faqTab.gettingStarted.paypal.${item.text}`)" />
            <img class="w-100" :src="item.image" :alt="$t(`faqTab.gettingStarted.paypal.${item.text}`)">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            stepContentList: [
                {
                    image: require("../assets/step3.1.png"),
                    text: "dashboard"
                },
                {
                    image: require("../assets/step3.2.png"),
                    text: "redirected"
                }, 
                {
                    image: require("../assets/step3.3.png"),
                    text: "choosing"
                },
                {
                    image: require("../assets/step3.4.png"),
                    text: "firstTime"
                },
                {
                    image: require("../assets/step3.5.png"),
                    text: "confirmed"
                }, 
                {
                    image: require("../assets/step3.6.png"),
                    text: "suitable"
                },
                {
                    image: require("../assets/step3.7.png"),
                    text: "movedPage"
                }
            ]
        }
    }
}
</script>